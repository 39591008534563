.postivefactor {
  margin-bottom: 10px;
}

.percentageColor {
  font-weight: 400;
  font-size: 40px;
  line-height: 110%;
}
.circular-bar {
  position: relative;
  cursor: pointer;

  .circle-img {
    position: absolute;
    width: 85%;
    height: 85%;
  }
  .progressDimond {
    position: absolute;
    height: 60%;
    z-index: 3;
  }
  .circle-star {
    position: absolute;
    top: 10%;
    left: 8%;
    right: 2%;
    height: 82%;
    width: 84%;
  }
}

@media only screen and (max-width: 1299px) {
  .res-row {
    justify-content: center;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1299px) {
  .admindashboard-progress {
    padding: 0px 5px;
  }
  h6.isBigProgressBarHeading {
    font-size: 35px;
  }
  h6.percentageNumber {
    font-size: 30px !important;
  }
  .postivefactor span {
    font-size: 12px;
  }
  .admindashboard-progress .col-md-4 {
    padding: 0px;
  }
  .imgDiv1 {
    padding: 15px 0px;
  }
  .component-section .circular-bar {
    width: 50% !important;
  }
  .bigCircle .circular-bar {
    width: 55% !important;
  }
  .progressbar-component-heading {
    font-size: 12px;
  }
  // .component-section {
  //   padding-bottom: 8px;
  // }
  .sechover {
    margin: 5px;
    padding: 5px 3px;
  }
}
@media only screen and (min-width: 1299px) {
  h6.isBigProgressBarHeading {
    font-size: 40px;
  }
}
@media only screen and (max-width: 991px) {
  .component-section {
    margin-top: 0px;
  }
  .progressbar-component-heading {
    margin-top: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .component-section .circular-bar {
    width: 70% !important;
  }
  .percentageColor {
    font-size: 22px;
  }
}

@media only screen and (min-width: 900px) {
  .percentageColor {
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
  }
  h6.percentageNumber {
    font-size: 30px;
  }
}
.circular-bar {
  position: relative;

  padding-top: 6px;

  .circle-img {
    position: absolute;
    width: 84%;
  }
}

@media only screen and (max-width: 600px) {
}

.Value {
  font-weight: 400;
  font-size: 40px;
}

h6.isBigProgressBarHeading {
  width: fit-content;
  margin: auto;
  border-radius: 50%;
  background-image: radial-gradient(
    circle,
    black,
    rgb(0 0 0 / 82%),
    transparent
  );
  font-weight: 400;
  padding: 3px 6px;
}
h6.percentageNumber {
  width: fit-content;
  margin: auto;
  border-radius: 50%;
  background-image: radial-gradient(
    circle,
    #070c12,
    rgb(0 0 0 / 82%),
    transparent
  );
  padding: 3px;
}
.component-section:hover {
  background-color: #676d7c42;
  border-radius: 20px;
}
