.reportsec {
  .form-check-input:checked {
    background-color: #27ae60;
    border-color: #27ae60;
    box-shadow: none !important;
  }
  .form-check-input {
    box-shadow: none !important;
  }
  .modalHeading38 {
    color: #676d7c;
  }
}
.modal-body {
  padding-top: 0 !important;
}
button.btn-close {
  margin-right: 25px;
}
