@font-face {
  font-family: "HelveticaNeueCyrThin";
  src: url("./assets/fonts/HelveticaNeueCyrThin.eot");
  src: url("./assets/fonts/HelveticaNeueCyrThin.eot")
    format("embedded-opentype");
  src: url("./assets/fonts/HelveticaNeueCyrThin.woff2") format("woff2");
  src: url("./assets/fonts/HelveticaNeueCyrThin.woff") format("woff");
  src: url("./assets/fonts/HelveticaNeueCyrThin.ttf") format("truetype");
  src: url("./assets/fonts/HelveticaNeueCyrThin.svg#HelveticaNeueCyrThin")
    format("svg");
}

@font-face {
  font-family: "HelveticaNeueCyrRoman";
  src: url("./assets/fonts/HelveticaNeueCyrRoman.eot");
  src: url("./assets/fonts/HelveticaNeueCyrRoman.eot")
      format("embedded-opentype"),
    url("./assets/fonts/HelveticaNeueCyrRoman.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNeueCyrRoman.woff") format("woff"),
    url("./assets/fonts/HelveticaNeueCyrRoman.ttf") format("truetype"),
    url("./assets/fonts/HelveticaNeueCyrRoman.svg#HelveticaNeueCyrRoman")
      format("svg");
}
@font-face {
  font-family: "HelveticaNeueCyrMedium";
  src: url("./assets/fonts/HelveticaNeueCyrMedium.eot");
  src: url("./assets/fonts/HelveticaNeueCyrMedium.eot")
      format("embedded-opentype"),
    url("./assets/fonts/HelveticaNeueCyrMedium.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNeueCyrMedium.woff") format("woff"),
    url("./assets/fonts/HelveticaNeueCyrMedium.ttf") format("truetype"),
    url("./assets/fonts/HelveticaNeueCyrMedium.svg#HelveticaNeueCyrMedium")
      format("svg");
}
@font-face {
  font-family: "HelveticaNeueCyrLight";
  src: url("./assets/fonts/HelveticaNeueCyrLight.eot");
  src: url("./assets/fonts/HelveticaNeueCyrLight.eot")
      format("embedded-opentype"),
    url("./assets/fonts/HelveticaNeueCyrLight.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNeueCyrLight.woff") format("woff"),
    url("./assets/fonts/HelveticaNeueCyrLight.ttf") format("truetype"),
    url("./assets/fonts/HelveticaNeueCyrLight.svg#HelveticaNeueCyrLight")
      format("svg");
}

@font-face {
  font-family: "HelveticaNeueCyrBlack";
  src: url("./assets/fonts/HelveticaNeueCyrBlack.eot");
  src: url("./assets/fonts/HelveticaNeueCyrBlack.eot")
      format("embedded-opentype"),
    url("./assets/fonts/HelveticaNeueCyrBlack.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNeueCyrBlack.woff") format("woff"),
    url("./assets/fonts/HelveticaNeueCyrBlack.ttf") format("truetype"),
    url("./assets/fonts/HelveticaNeueCyrBlack.svg#HelveticaNeueCyrBlack")
      format("svg");
}

@font-face {
  font-family: "HelveticaNeueCyrLight";
  src: url("./assets/fonts/HelveticaNeueCyrLight.eot");
  src: url("./assets/fonts/HelveticaNeueCyrLight.eot")
      format("embedded-opentype"),
    url("./assets/fonts/HelveticaNeueCyrLight.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNeueCyrLight.woff") format("woff"),
    url("./assets/fonts/HelveticaNeueCyrLight.ttf") format("truetype"),
    url("./assets/fonts/HelveticaNeueCyrLight.svg#HelveticaNeueCyrLight")
      format("svg");
}

@font-face {
  font-family: "HelveticaNeueCyrBlack";
  src: url("./assets/fonts/HelveticaNeueCyrBlack.eot");
  src: url("./assets/fonts/HelveticaNeueCyrBlack.eot")
      format("embedded-opentype"),
    url("./assets/fonts/HelveticaNeueCyrBlack.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNeueCyrBlack.woff") format("woff"),
    url("./assets/fonts/HelveticaNeueCyrBlack.ttf") format("truetype"),
    url("./assets/fonts/HelveticaNeueCyrBlack.svg#HelveticaNeueCyrBlack")
      format("svg");
}
:root {
  --bs-black: #202328;
  --bs-light-black: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
}
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-weight: normal;
  font-style: normal;
  overflow-x: hidden;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif !important;
}

img {
  max-width: 100%;
}

ul,
ol,
li {
  list-style: none;
}
a:focus,
input:focus,
textarea:focus,
button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  text-decoration: none;
  transition: 0.3s;
}

a:hover {
  color: #202328 !important;
}

a,
span {
  display: inline-block;
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  font-weight: 700;
}

.wrapper {
  display: flex;
}
.side-dashboard {
  width: 274px;
  display: block;
  z-index: 1;
  background-color: #fff;
}
.side-dashboard .logo-section {
  text-align: center;
}
.side-dashboard li {
  list-style: none;
}
.sidebar-ul ul {
  padding-right: 2.5rem;
}
.sidebar-anchor {
  color: #676d7c;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  display: inline-flex;
  width: 200px;
  height: 44px;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  font-family: "HelveticaNeueCyrRoman";
}

.side-dashboard img.sidebar-icons {
  padding-right: 10px;
}
.side-dashboard .sidebar-ul {
  margin-top: 60px;
}
.MainPannel {
  width: calc(100% - 272px);
  background-image: url(./assets/img/bg.png);
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
}

.component-heading-Big {
  color: #fff;
  margin-top: 15px;
  font-size: 22px;
}
.component-heading {
  color: #dde1e3;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
.sidebar_logo {
  margin-top: 30px;
}

.component-section svg {
  height: auto;
}

.textClr {
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.imgDiv {
  position: relative;
}

.clr-red {
  color: #eb5757;
}
.clr-yellow {
  color: #f2994a;
}
.clr-green {
  color: #27ae60 !important;
}
.clr-white {
  color: white;
}

.clr-gray {
  color: #585a5e;
}

.sidebar-anchor:hover {
  background: #dde1e3;
  border-radius: 10px;
}

.sidebar-anchor.active {
  background: #dde1e3;
  border-radius: 10px;
  font-weight: 550;
  color: #202328;
}

.listitem:not(:last-child) {
  margin-bottom: 10px;
}

.component-section1 .component-heading {
  margin-top: 20px;
}

.mobileheader {
  display: none;
}

.side-dashboard {
  display: block;
}

.scorecard {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
}

.heading3 {
  color: #dde1e3;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 1px;
  vertical-align: sub;
  display: flex;
  align-items: center;
}

.arrow {
  margin-right: 10px;
}

.bgclr {
  background: #070c12;
  min-height: calc(100vh - 0px);
}

.spaceN1 {
  padding: 0px 30px;
}

.serc {
  padding-top: 25px;
  padding-bottom: 25px;
}

.cordsySec .component-section1:hover {
  box-shadow: 0px 4px 6px rgb(0 0 0 / 6%);
  background: rgb(103 109 124 / 26%);
}

.cordsySec .component-section1 {
  border: 1px solid #202328;
  border-radius: 20px;
  text-align: center;
  padding: 20px 34px;
}

.component-heading1 {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #dde1e3;
  line-height: 17px;
  margin-top: 15px;
}

.componetsbox {
  display: flex;
  overflow-x: scroll;
  padding-bottom: 30px;
  flex-wrap: nowrap;
}
::-webkit-scrollbar {
  height: 5px;
}
::-webkit-scrollbar-track {
  background: #202328;
}
::-webkit-scrollbar-thumb {
  background: #676d7c;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.factorclr {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-left: 13px;
  margin-bottom: 15px;
}

.postivefactor span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #dde1e3;
}

.postivefactor {
  display: flex;
  border: 1px solid #202328;
  border-radius: 20px;
  padding: 10px 19px;
}

.postivefactor li {
  position: relative;
}

.postivefactor li::after {
  content: "";
  background: #6fcf97;
  position: absolute;
  width: 5px;
  height: 5px;
  top: 9px;
  left: -20px;
  border-radius: 50%;
}

.postivefactor2 li::after {
  background: #eb5757;
}

.imgDiv1 {
  padding: 30px 0px;
}

.headdiv {
  display: flex;
  justify-content: space-between;
}

.para {
  color: #676d7c;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  display: inline-block;
}

.component-box {
  background-color: #202328;
  height: 193px;
  width: 100%;
  padding: 20px;
  position: relative;
  border-radius: 10px;
}

.component-box::before {
  position: absolute;
  bottom: -45px;
  left: 0;
  background-image: url(./assets/img/Untitled-14.png);
  width: 100%;
  height: 50px;
  content: "";
  background-repeat: no-repeat;
  background-size: contain;
}

.boxtitle5 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
}

.boxclr {
  border-radius: 0px 10px;
  padding: 10px 15px 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: right;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  line-height: 8px;
  vertical-align: top;
}

.boxred {
  background: #eb5757;
}

.boxorg {
  background: #f2994a;
}

.boxgreen {
  background: #27ae60;
}

.component-box {
  position: relative;
}

.numbertile {
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}

.component-box .para {
  color: #676d7c;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  min-height: 50px;
}
.orange-box.slider {
  background: #f2994a;
}

.boxgreen.slider {
  background: #27ae60;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ffff;
  border: 4px solid #ffff;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.35);
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #070c12;
  border: 4px solid #eb5757;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.35);
  cursor: pointer;
}

.inputslider .slider {
  /* background: linear-gradient(
    90deg,
    #e65050 4.69%,
    #e69850 25%,
    #50e67a 38.54%,
    #50e67b 55.21%,
    #e69850 68.75%,
    #e65050 94.27%
  ); */
  
  display: inline;
  -webkit-appearance: none;
  height: 5px;
  border-radius: 5px;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  width: 70%;
  margin: 0px auto;
  position: relative;
  z-index: 99;
}

.inputslider {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: 50px;
}

.inputslider p {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: -17px;
  font-weight: 700;
  font-size: 12px;
  color: #dde1e3;
}

.mbb {
  margin-bottom: 65px;
}
.bloodresult {
  height: 100vh;
}

.blood-result {
  margin-top: 60px;
}

.progressSec ul {
  border: none;
  padding: 0px;
  border-radius: 30px;
  justify-content: space-between;
  width: fit-content;
  height: 42px;
  text-align: center;
  display: flex;
  flex-direction: row;
  margin: 0 auto 20px;
  background-color: #202328;
}

.progressSec .nav-tabs .nav-link {
  color: #aeb6c2;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  border: none;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 21px;
  border-radius: 30px;
  line-height: 25px;
}

.progressSec .nav-tabs .nav-link.active {
  background: #676d7c;
  border-radius: 30px;
  color: #dde1e3;
}

.search-bar {
  background: #ffffff;
  border-radius: 4px;
  border-radius: 4px;
  height: 45px;
  z-index: 3;
  position: relative;
  background: transparent;
  border-radius: 32px;
  border-bottom: 1px solid #202328;
  border-radius: 0px;
}
.search-bar input {
  height: 100%;
  width: 100%;
  display: block;
  background-color: transparent;
  border: none;
  padding-left: 30px;
  background-image: url(./assets/img/Search-ico.png);
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 1px 50%;
  color: #676d7c;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
}

.form-select {
  border: 1px solid #676d7c;
  border-radius: 10px;
  width: 100%;
  height: 40px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  background-color: transparent;
  color: #fff;
}

.form-select span {
  color: #676d7c !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

select option {
  background: #070c12;
  border: 1px solid #676d7c;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 5px 0px;
  color: #fff;
}

select:focus {
  border-color: #676d7c !important;
  box-shadow: none !important;
}
select option:hover,
select:hover {
  background: #202328 !important;
  border-radius: 10px;
}

.t-r thead th {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #dde1e3;
  border-color: #202328;
  padding: 13px;
}

.t-r tbody th {
  padding-left: 10px;
}
.t-r thead th:first-child {
  padding-left: 10px;
}
.t-r td {
  font-weight: 400;
  font-size: 22px;
  color: #dde1e3;
  border-color: #202328;
}

.t-r tr:last-child td {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.t-r tr:last-child th {
  border-bottom: 0px;
}
.t-r tbody th {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #dde1e3;
  vertical-align: middle;
  border-color: #202328;
}
.greenclr {
  color: #27ae60 !important;
}

.redclr {
  color: #eb5757 !important;
}

.orgclr {
  color: #f2994a !important;
}

.graphimage {
  position: relative;
}

.listitem {
  position: relative;
}

.t-r th:not(:first-child) {
  border-color: #202328;
  border-top: 1px solid #202328;
  padding-top: 15px !important;
  border-right: 1px solid #202328;
  border-left: 1px solid #202328;
  margin-right: 50px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 18%;
  text-align: center;
  padding-bottom: 13px;
}

.t-r td:not(:first-child) {
  border-color: #202328;
  border-bottom: 1px solid #202328;
  border-right: 1px solid #202328;
  border-left: 1px solid #202328;
  width: 18%;
  text-align: center;
  padding: 13px;
}

.t-r th:nth-child(n + 3),
.t-r td:nth-child(n + 3) {
  background: rgb(221 225 227 / 5%);
}

.table > :not(:last-child) > :last-child > * {
  border-color: #202328;
  min-width: 100px;
}

.t-r .table {
  border-collapse: separate;
  border-spacing: 8px 0px;
}

.label {
  color: #dde1e3;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0pxpx;
}

.imgdiv {
  margin-top: 30px;
}

.graphimage {
  padding-top: 20px;
}

.btn-greeen {
  background: #27ae60 !important;
  border-radius: 10px;
  padding: 4px 10px 4px;
  width: 160px;
  /* height: 39px; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  line-height: 28px;
  letter-spacing: 0.5;
  text-align: center ;
}
a.btn-greeen:hover {
  color: #fff !important;
}

.paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #676d7c;
  margin-top: 10px;
}

.progressSec p {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #dde1e3;
}

.innerpro {
  background: #070c12;
  border-radius: 10px;
  padding: 15px 15px 25px;
}
.innerpro h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #dde1e3;
  padding: 10px 7px;
  width: -webkit-fill-available;
}
.kpitable{
max-height:500px}
/* width */
  ::-webkit-scrollbar {
  width: 4px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.kpitable tr.isActive-heading td:not(:first-child)::before {
  position: absolute;
  content: "";
  background-color: rgb(13 44 34 / 70%);
  right: -13px;
  width: 12px;
  height: 59px;
  bottom: 0px;
}

.kpitable tbody tr.isActive-heading td:last-child::before {
  content: unset;
}
.kpitable tbody tr td:not(:first-child),
.kpitable thead tr th:not(:first-child) {
  width: 112px;
}

.kpitable tbody tr.isActive-heading th::before {
  position: absolute;
  content: "";
  background-color: #0d2c22;
  right: -12px;
  width: 12px;
  height: 59px;
  bottom: 0px;
}

.kpitable tbody tr:last-child th::before {
  content: unset;
}
.kpitable tr:last-child td::before {
  content: unset;
}

.divimg > canvas {
  width: 100%;
  height: 20px;
}
.tb {
  justify-content: space-between;
  /* padding-bottom: 30px; */
}

.secf {
  flex: 0 0 250px;
}

.bt-table {
  padding-top: 15px;
}
.bt-table th {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #676d7c;
  border-bottom: 1px solid rgb(32 35 40);
}
.progressColor .bt-table th {
  border-bottom: 0 ¡important;
}
.innerpro .table > :not(:last-child) > :last-child > * {
  border-color: rgb(32 35 40);
}
.progressSec .table > :not(:last-child) > :last-child > * {
  border-color: #676d7c4f;
}
.progressSec .bt-table td {
  border-bottom: 1px solid #676d7c4f;
}
.progressSec .bt-table th {
  border-bottom: 1px solid #676d7c4f;
}
.modal-body .table > :not(:last-child) > :last-child > * {
  border-color: rgb(32 35 40);
}
.bt-table td {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #676d7c;
  border-bottom: 1px solid rgb(32 35 40);
}

.ornclr {
  color: #f2994a !important;
}

.checkresult {
  height: 100%;
}

.tb2 {
  padding-bottom: 0px;
}

.graphimage {
  display: none;
}

#kpitable tbody tr:nth-child(1):hover .graphimage1 {
  display: block;
}

#kpitable tbody tr:nth-child(2):hover .graphimage2 {
  display: block;
}

.graphimage {
  display: none;
  position: absolute;
  top: 0;
}
.listitem-view {
  position: relative;
  height: 100%;
}

.mobileview {
  display: none;
}

.mobileview .modal-content {
  background: #202328;
  border-radius: 20px;
}

.close {
  background-color: transparent;
  border: none;
}

.mobileview .modal-header {
  border-bottom: 0px;
}

.mobileview .modal-title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #dde1e3;
}

.report-popup .modal-content {
  background: #202328;
  box-shadow: 0px 6px 20px rgb(0 0 0 / 45%);
  border-radius: 10px;
}

.report-popup .modal-title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #dde1e3;
}

.report-popup .modal-header {
  border-bottom: 0px;
  border-bottom: 1px solid rgb(103 109 124 / 20%);
  padding: 25px 25px;
}

.report-popup .modal-body {
  padding: 0px;
}
.form-check-label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #dde1e3;
}

.report-popup .form-check-input:checked {
  background-color: #27ae60 !important;
  border-color: #27ae60 !important;
}

.report-popup .form-check .form-check-input {
  transform: scale(1.3);
  box-shadow: unset;
  background-color: transparent;
  border: 1px solid rgb(103 109 124 / 20%);
}

.rp-sec {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(103 109 124 / 20%);
  padding-bottom: 20px;
  align-items: center;
  padding: 10px 30px;
}

.rp-sec2 ul {
  padding-left: 0px;
}

.report-popup .btn-greeen {
  width: 129px;
  height: 47px;
  padding: 10px 20px;
}

.rp-sec2 {
  padding: 18px 30px;
  height: 360px;
  overflow-y: auto;
}

.repot-list li {
  padding: 4px 0px;
}

.form-check-label {
  margin-left: 10px;
}

/*-----------------------admin----------------------*/

.topnav .navbar {
  padding-left: 0;
  height: 65px;
  z-index: 1039;
  font-size: 0.9rem;
}

.topnav .navbar-expand-lg .navbar-nav {
  align-items: center;
}
.topnav .nav-item {
  position: relative;
}

.topnav .nav-link {
  position: relative;
}
.topnav .nav-link span {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  background: #eb5757;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-toggle::after {
  display: none;
}

.topnav {
  padding: 0px 30px;
}

.topnav .nav-item:last-child {
  margin-left: 15px;
}

.topnav .dropdown-menu[data-bs-popper] {
  width: 100%;
  border: 1px solid #dde1e3;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  border-radius: 10px;
}
.topnav a.nav-link.client-child {
  font-size: 14px;
  padding: 10px !important;
}
.topnav ul ul {
  padding: 5px 3px 10px;
}
.topnav ul ul li a {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #202328;
  padding: 10px 15px;
}

.topnav ul .userprofile ul li:hover {
  background: #dde1e3;
  border-radius: 10px;
}

.topnav ul .notification ul {
  padding: 5px;
}

.topnav ul .notification a {
  padding: 15px 15px;
}
.notificationDropdown {
  width: 300px !important;
}
#navbarDropdown1::after,
.clients-list .dropdown::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.topnav ul .notification ul li:not(:last-child) a {
  border-bottom: 1px solid #dde1e3;
}

.topnav ul .notification ul li:last-child a {
  font-weight: 700;
}
.topnav ul .userprofile ul li:first-child a {
  font-weight: 700;
}

.search-bar1 input {
  height: 100%;
  width: 100%;
  display: block;
  background-color: transparent;
  border: none;
  padding-left: 30px;
  background-position: left;
  color: #202328;
  font-weight: 400;
}

.search-bar1 input::placeholder {
  color: #202328;
  font-size: 13px;
}
.search-bar1 {
  position: relative;
}

.search-bar1::after {
  content: "";
  position: absolute;
  top: 15px;
  left: 0;
  width: 20px;
  height: 20px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFNSURBVHgBvZM7bsJAEIZndi1TpOEIyw1ygUi4RFGkOFJqxAkQJwCOkBNARxERFxGhXCAX4Ah7BKdIEeTdyXgVkIk2BikSf+FZj3Y+z8sA/xSGnJNMN6/iWH3udqaXJvnZgNlSqwbJCR/bFbex1vYe75JVLeAnWANQkwieyLotRlIhUJ+vKYYkIUi0P8Qgh2wUfrnWQ5qYvT/L9JRioaX0mbV+A8QhFYJ7RJimleBS/J5b5wYl/PlVt4OAjNNn03QO1hBQFIHxl4VUQUDa8V/Nud7rEKAoIh/onDVBgC8BYAWI3VnmszmoHKmUxPWTqW0ioB0ACd1oSD1fbMbOoRGCeArIU2CLOIWAjvbA94L3gI72gAw/uDfYZcgo7dyM/wRUQUUBKhKQp7fJtvTNF+sRA4YhyNkqIS9vG8qW78PaDE5BeF/6H7xwp/6Ty+gbDKmHKZcP6cMAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-size: contain;
  filter: brightness(0.1);
}

.search-bar2 input {
  height: 100%;
  width: 100%;
  display: block;
  background-color: transparent;
  border: none;
  padding-left: 20px;
  background-image: url(./assets/img/Search-ico.png);
  background-repeat: no-repeat;
  background-size: 11%;
  background-position: left bottom;
  color: #aeb6c2;
  font-weight: 400;
  font-size: 12px;
}

.parameter-table th:first-child,
.parameter-table td:first-child {
  padding-left: 10px;
}

.search-bar2 {
  background: #ffffff;
  border-radius: 4px;
  border-radius: 4px;
  padding-left: 0px;
  z-index: 3;
  position: relative;
  background-color: transparent;
  border-radius: 32px;
  font-size: 20px;
}

.adminwraper .sidenav-menu-nested {
  flex-direction: column;
}

.menu-side .nav-link {
  font-weight: 700;
  font-size: 14px;
  color: #202328 !important;
}

.menu-side .item {
  padding: 17px 28px;
  border-top: 1px solid rgb(0 0 0 / 12%);
  border-bottom: 1px solid rgb(0 0 0 / 12%);
}
.menu-side .nav-list {
  padding-left: 0px;
  border-top: 1px solid rgb(0 0 0 / 12%);
}

.sub-list a {
  color: #676d7c;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding: 4px 0px;
  display: inline-block;
  letter-spacing: 0.3px;
  transition: 0.3s;
  opacity: 0.7;
}

.sub-list {
  padding-top: 8px;
}
.sub-list .active {
  font-weight: 700 !important;
  opacity: 1;
}

.sub-list a:hover {
  font-weight: 700;
  opacity: 0.7;
}
.menu-side .dropdown,
.clients-list .dropdown {
  position: relative;
  cursor: pointer;
}

.menu-side .dropdown::after,
.clients-list .dropdown::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.sidenav-menu-nested a {
  font-weight: 400 !important;
}

.sechover:hover {
  background: #202328;
  border-radius: 10px;
}

.admininner-sec {
  position: relative;
}

.admininner-sec {
  padding-left: 25px;
  height: 100%;
}
.AdminBorder {
  border-left: 1px solid #202328;
  height: calc(100vh - 65px);
}

.parameter-table th {
  color: #676d7c;
  font-style: normal;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #676d7c;
  min-width: 150px;
}

.parameter-table tbody th {
  font-size: 12px;
  font-weight: 700;
}
.search-bar2 input::placeholder {
  font-size: 14px;
}

.parameter-table td {
  color: #dde1e3;
  font-style: normal;
  font-weight: 550;
  padding: 10px;
  border-bottom: 1px solid #676d7c;
}

.parameter-table tbody th {
  width: 16%;
}

.parameter-table {
  padding-top: 20px;
}

.parameter-table {
  overflow-x: scroll;
  white-space: nowrap;
  max-width: 1087px;
  width: 100%;
  overflow-y: auto;
}

.parasec {
  overflow-x: scroll;
  white-space: nowrap;
  max-width: 978px;
  width: 100%;
}

.adminwraper .admin-panal {
  background: #070c12;
  min-height: calc(100vh - 104px);
}
.clients-list a {
  font-weight: 700;
  font-size: 20px;
  color: #202328 !important;
}

.adminMobile {
  display: none;
}

.sectr {
  display: flex;
  justify-content: space-between;
}

.kpitable thead th {
  border-bottom: 1px solid #202328 !important;
}

.adminvh {
  min-height: 1200px;
}
.spaceN2 {
  padding: 25px 30px;
}
.modal-content {
  background-color: #202328 !important;
  color: white !important;
  border-radius: 20px !important;
}

.kpitable th,
.kpitable td {
  position: relative;
}

.kpitable th:not(:last-child)::after,
.kpitable td:not(:last-child)::after {
  position: absolute;
  content: "";
  background-color: #202328;
  right: -19px;
  width: 19px;
  height: 1px;
  bottom: -1px;
}

.t-r tbody tr:last-child th::after,
.t-r tbody tr:last-child td::after {
  content: unset;
}
nav.sidenav-menu-nested.nav div {
  margin: 6px 0;
  padding: 0 15px;
}
.t-r tbody tr:last-child th::after,
.t-r tbody tr:last-child td::after {
  content: unset;
}

ul.samllDropdown {
  width: 160px !important;
}

li.darkDashboard {
  background-color: #dde1e33b;
}

.user-name {
  cursor: pointer;
  padding-left: 10px;
  padding: "20px";
  margin: 5px;
  border-radius: 0.5rem;
}
.user-name:hover {
  background-color: #e9e9e9;
  color: #202328;
  padding: 0px 15px;
}

.user-name.active {
  color: #202328;
  padding: 0px 15px;
}

.component-section {
  text-align: center;
  /* margin-top: 10px; */
}
.menu-side .alexandorHeading {
  font-size: 14px !important;
}
.parameter-table td {
  font-size: 14px;
}
.innerpro td.resultTable {
  color: #fff;
  font-size: 14px;
  font-weight: 550;
  white-space: nowrap;
}
.inputslider p {
  font-size: 12px;
}
p.para {
  margin: 0;
}
h5.boxtitle5 {
  min-height: 40px;
  width: 75%;
}

h6.percentageNumber {
  font-weight: 400;
}
.secActive {
  background-color: #202328;
  border-radius: 10px;
}

.fade.custompopup.modal.show {
  padding-left: 0 !important;
}
a.nav-link.dropdown.collapsed.clientsHeading {
  font-size: 16px;
}
body input::placeholder {
  color: #aeb6c2 !important;
}
ul.dropdown-menu.samllDropdown.show .dropdown-item {
  font-size: 16px;
}
td sub {
  bottom: -0.25em;
  font-size: 12px;
  margin-left: 4px;
}
td.greyClr {
  color: #676d7c;
}
.numbertile {
  position: relative;
  top: 15px;
}
.navbar-nav .dropdown-menu {
  position: absolute;
}
.notificationDropdown a.dropdown-item:active {
  background-color: transparent;
  color: black !important;
}
ul.samllDropdown.show {
  left: -130px;
}
.ProgressBigSec {
  padding: 0;
  margin-left: 0;
}
/* .bt-table tr th:first-child {
  position: sticky;
  left: 0;
  overflow: auto;
  /* background-color: #070c12; */
  /* width: 21%; */
/* }  */
.modal-body .bt-table tr th:first-child {
  background-color: #202328;
}
.optimal-range {
  font-size: 12px !important;
  color: #676d7c !important;
}

img.searchCloseIcon {
  position: absolute;
  top: 15px;
  right: 0;
  width: 16px;
  background-color: #fff;
  cursor: pointer;
}
/* Search */
.HideSearch {
  visibility: hidden;
}
.ClientSearch {
  padding: 15px 0px;
}
.ClientSearchPhone {
  padding: 15px 0px;
  width: 90%;
  margin: auto;
}
.ClientSearchPhone input {
  padding: 0 25px;
}
.HiddenSearch {
  display: none;
}
p.phoneHeading {
  color: #202328;
  margin: 0;
  font-size: 16px;
  font-weight: 700;
}

.kpi-blood-result .headdiv {
  padding: 16px 12px 0px;
}

.kpi-graph-shared {
  padding-top: 30px;
  padding-left: 12px;
}
table#kpitable {
  margin-top: 0px;
}
.component-box a:hover {
  color: white !important;
}
.sec0 {
  position: relative;
  top: -28px;
}
.bloodResultSec {
  padding: 24px 30px;
  padding-right: 0;
}
.AdminAdjust {
  height: calc(100vh - 65px);
  overflow: hidden;
}
.ProgImg {
  border-bottom: 1px solid #676d7c;
}
.modal-body .bt-table td {
  border-bottom: 1px solid rgb(63 68 76);
  white-space: nowrap;
}
.modal-body .bt-table th {
  border-bottom: 1px solid rgb(63 68 76);
}
.modal-body .bt-table thead tr th {
  border-bottom: 1px solid rgb(63 68 76);
}
.frame-icon {
  width: -webkit-fill-available;
  text-align: end;
}
.icon {
  width: -webkit-fill-available;
  text-align: end;
}
.checkresult {
  padding: 30px 15px;
}
hr.modalHR {
  border-color: #676d7c;
  opacity: 20%;
  margin-bottom: 0;
  margin: 0;
}
.form-check-input {
  background-color: transparent !important;
  border: 1px solid #676d7c !important;
}
.reportsec .form-check-input:checked {
  background-color: #27ae60 !important;
}
button.btn-close {
  box-shadow: none !important;
}
.progressBG {
  background-color: #202328;
}
.reportHeading {
  font-size: 24px !important;
}
.justify-content-between {
  align-items: flex-end;
}
.modal-body .btn-greeen {
  font-size: 16px;    
  cursor: pointer;
}
.parameter-table {
  max-height: 500px;
}
.parameter-table::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background: #888; 
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.optimal-range{
  font-size: 12px;
  color: #676d7c !important;
}
th.FixedHeading {
  position: sticky;
  z-index: 2;
  left: -2px;
  background-color: #070c12 !important;
}