@media (min-width: 1500px) {
  .inputslider .slider {
    width: 64%;
  }.bluod-result {
    margin-top: 20px !important;
  }
  .pd1 {
    position: relative;
    top: -7px;
  }
  .inputslider {
    top: 95px;
  }
}
@media (min-width: 1701px) and (max-width: 2560px) {
  .inputslider {
    top: 60px;
  }
}

@media (min-width: 1300px) {
}

@media (min-width: 1501px) and (max-width: 1700px) {
  .inputslider {
    top: 50px;
  }
  .MainPannel {
    padding: 20px;
    padding-bottom: 0;
  }
  .checkresult {
    padding-top: 35px;
    padding-left: 30px;
}
}
@media (min-width: 992px) and (max-width: 1200px) {
  .MainPannel {
    padding: 25px 10px;
  }
}
@media (min-width: 768px) {
  .logoutIcon {
    padding-left: 2px;
    width: 35px;
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
  }
}
@media (min-width: 992px) {
  .MainPannel {
    min-height: calc(100vh - 0px);
  }
  .user-main-bg{
    display: flex;
    align-items: center;
    padding: 0;
  }
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .inputslider {
    top: 50px;
  }.bluod-result {
    margin-top: 40px !important;
  }
  // .MainPannel {
  //   padding: 10px 15px;
  // }
//   .user-main-bg {
//     padding-top: 35px;
// }
  .pd1 {
  position: relative;
  top: -7px;
}
}
@media (min-width: 931px) and (max-width: 991px) {
  .inputslider {
    top: 55px;
  }
}
@media (min-width: 931px) and (max-width: 1200px) {
  .MainPannel {
    padding: 30px 15px;
  }  .pd1 {
    position: relative;
    top: -7px;
  }.bluod-result {
    margin-top: 15px;
}
 
  .postivefactor1 {
    margin-left: 0 !important;
    padding: 26px 13px;
  }
  .postivefactor2 {
    margin-right: 0 !important;
    padding: 26px 13px;
  }
}
@media (min-width: 768px) and (max-width: 931px) {
  .inputslider {
    top: 50px;
  }
  .bluod-result {
    margin-top: 30px !important;
  }
  .ScoreChild .imgDiv {
    width: 120px !important;
  }
  .MainPannel {
    padding: 30px 0;
  }
  h6.isBigProgressBarHeading {
    font-size: 35px;
  }
  h6.percentageNumber {
    font-size: 24px;
  }
  .postivefactor1 {
    margin-left: 0 !important;
    min-height: 200px;
  }
  .postivefactor2 {
    margin-right: 0 !important;
    padding: 26px 11px;
    min-height: 200px;
  }
  button.btn-close {
    margin-right: 15px !important;
  }
  table {
    border-collapse: separate !important;
  }
  .bigCircle .circular-bar {
    width: 100% !important;
  }
  .circular-bar{
  width: 80% !important;
  }
  .component-section {
  margin-top: 30px;
  }
    .pd1 {
    position: relative;
    top: -7px;
  }
  .ScoreChild .component-section1{
    height: 170px;
  }
}
@media (min-width: 576px){
.modal-dialog {
    max-width: 600px !important;
}
}
@media (min-width: 992px){
.modal-lg, .modal-xl {
    --bs-modal-width: 600px;
}}
@media (max-width: 991px) {
  .t-r th:not(:first-child) {
    width: 21%;
  }
  .space {
    padding-left: 5px;
    padding-right: 5px;
  }
  .destopview {
    display: none;
  }
  .mobileview {
    display: block;
  }
  .sidebar-anchor {
    width: 100%;
    border-radius: 0px !important;
    flex-direction: row-reverse;
    justify-content: space-between;
    height: 54px;
    border-bottom: 1px solid #dde1e3;
    padding-right: 20px;
  }
  .listitem:not(:last-child) {
    margin-bottom: 0px;
  }
  a.Web-View {
    display: none;
  }
  .mobileheader .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
  }
  .mobileheader .navbar-brand {
    padding-left: 15px;
  }
  .mobileheader {
    display: block;
    width: 100%;
    position: absolute;
    z-index: 15;
    background-color: #fff;
  }

  .side-dashboard {
    display: none;
  }
  .wrapper {
    flex-direction: column;
  }
  .MainPannel {
    width: 100%;
    min-height: calc(100vh - 64px);
    margin-top: 64px;
  }

  .cordsySec {
    height: auto;
    padding: 20px 10px;
  }
  #nav-icon1 {
    width: 35px;
    height: 19px;
    border: 0;
    float: right;
    margin-top: 0px;
    right: 20px;
    top: -10px;
    position: relative;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }
  #nav-icon1 span {
    display: block;
    height: 3px;
    width: 26px;
    background: #202328;
    border-radius: 9px;
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
  #nav-icon1 span:nth-child(1) {
    top: 0;
  }
  a[aria-expanded=true] .test1 {
    transform: translate(0, 5px) rotate(45deg) !important;
  }
  a[aria-expanded=true] .test2 {
    transform: translate(0, -8px) rotate(-45deg) !important;
  }
   .test2 {
    transform: translate(0, 0px) rotate(0deg) !important;
  }
  .test1 {
    transform: translate(0, 0px) rotate(0deg) !important;
  }

  #nav-icon1 span:nth-child(2) {
    top: 11px;
  }
  #nav-icon1.open span:nth-child(2) {
    top: 11px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
  #nav-icon1.open span:nth-child(2) {
    top: 5px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
  #nav-icon1.open span:nth-child(1) {
    top: 5px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  #nav-icon1 span,
  .mobileheader ul {
    padding-left: 0px;
    margin-top: 10px;
    margin-bottom: 0;
  }
  .navbar {
    padding: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 0 !important;
  }

  .sidebar-anchor.active,
  .sidebar-anchor:hover {
    background: rgb(238 240 241);
  }
  .componetsbox .col-2 {
    flex: 0 0 auto;
    width: 25.666667%;
  }
  .mbb {
    margin-bottom: 3.4rem;
  }
  .bluod-result {
    margin-top: 20px !important;
  }
}

@media (max-width: 767px) {
  .bluod-result {
    margin-top: 32px !important;
}
.spaceN2 .bluod-result {
  margin-top: 0 !important;
}
  .textClr {
    font-size: 22px;
  }
  h6.isBigProgressBarHeading {
    font-size: 34px;
  }
  .MainPannel {
    padding: 25px 0 !important;
    margin-top: 63px;
  }
  .component-heading {
    font-size: 14px;
    line-height: 20px;
  }
  .componetsbox .col-2 {
    flex: 0 0 auto;
    width: 54.666667%;
  }
  .heading3 {
    font-size: 24px;
  }
  .ScoreChild .heading3 {
    font-size: 22px;
  }
  .factorSec .col-lg-6:first-child {
    margin-bottom: 20px;
  }
  .inputslider .slider {
    width: 67%;
  }
  .mbb {
    margin-bottom: 60px;
  }
  .para1 {
    display: none;
  }
  .search-bar {
    margin-top: 10px;
  }
  h6.percentageNumber {
    font-size: 24px;
  }
  .postivefactor1 {
    margin-left: 0 !important;
    align-items: baseline;
  }
  .postivefactor2 {
    margin-right: 0 !important;
    align-items: baseline;
  }
  h6.progressbar-component-heading {
    font-size: 14px;
  }
  .progressSec ul {
    margin-top: 24px;
  }
  .bigHeading {
    font-size: 18px !important;
  }
  #nav-icon1 {
    right: 20px;
    top: -10px;
  }
  .t-r td {
    font-size: 20px;
  }
  .t-r tbody th {
    font-size: 14px;
  }
  p.ObserveHeading {
    font-size: 14px;
  }
  button.btn-close {
    margin-right: 0 !important;
  }
  .ScoreChild .component-section1 {
    padding: 10px 5px !important;
  }
  .ScoreChild .imgDiv {
    width: 85px !important;
  }
  .ScoreChild .cardio-system {
    margin: 0 5px !important;
  }
  .ScoreChild .component-section1 {
    margin-bottom: 10px !important;
    height: 150px;
  }
  .t-r thead th {
    font-size: 18px;
  }
  p.ObserveHeading{
  padding: 8px 0; 
  }
  img.sadFace {
    width: 39px;
}
.logoutIcon {
  padding-left: 0px;
  width: 20px;
}
}
@media only screen and (min-width: 400px) and (max-width: 476px) {
  .inputslider {
    top: 50px;
  }
}
@media only screen and (min-width: 476px) and (max-width: 767px) {
  .inputslider {
    top: 55px;
  }
}
@media (max-width: 400px) {
  .componetsbox .col-2 {
    flex: 0 0 auto;
    width: 61.666667%;
  }
  .component-box {
    padding: 20px 10px;
    height: 160px;
  }
  .inputslider {
    top: 20px;
  }
  .numbertile {
    top: 0;
  }
  h5.boxtitle5 {
    min-height: 45px;
    margin-bottom: 0;
  }

  .progressSec ul {
    width: 100%;
    margin-bottom: 42px;
  }
  .progressSec ul .nav-item {
    flex: 1;
  }

  .progressSec .nav-tabs .nav-link {
    width: 100%;
  }
  .report-popup .modal-header,
  .rp-sec,
  .rp-sec2 {
    padding: 10px 5px;
  }
  .btn-greeen {
    width: 145px;
    font-size: 12px;
  }
}

@media only screen and (min-width: 689px) and (max-width: 767px) {
  .component-box::before {
    background-size: cover;
  }
}
@media  (min-width: 1500px){
  .user-main-bg {
    padding-top: 35px;
}
}
@media (min-width: 320px) and (max-width: 440px){
  .MainPannel {
    min-height: calc(100vh - 63px);
}
}
@media (min-width: 992px) and (max-width: 1100px){
  .CheckupSec {
    width: 271px !important;
}
}
@media (min-width: 992px) and (max-width: 1200px){
  .CheckupSec{
    margin-bottom: 20px;
  }
}