.graphHeading {
  color: #dde1e3;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.6px;
  vertical-align: sub;
  display: flex;
  align-items: center;
}
.progressBar {
  display: flex;
  justify-content: center;
}

.progressbar-component-heading {
  color: #dde1e3;
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  margin: 5px;
}
.ProgressBigSec .imgDiv.progressBar {
  padding: 25px;
}
h2.PopupHeading {
  font-size: 24px;
  font-weight: 700;
  padding-top: 20px;
}
.progressbar-component-heading {
  padding-bottom: 10px;
}
.bigHeading {
  font-size: 22px;
}
