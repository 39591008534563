$small: 768px;
$medium: 992px;

.kpi-graph-shared {
  //some CSS
  @media screen and (max-width: $medium) {
    display: none;
  }
}
.kpi-pointer-check {
  cursor: pointer;
}

.isActive-kpi {
  background-color: #27ae60 !important;
}

.is-target,
.is-actual {
  margin-top: 10px;
  margin-left: 10px;
  font-weight: 550;
  font-size: 14px;
  line-height: 16.8px;
  color: #676d7c;
}
h4.KPI-PopupHeading {
  color: white;
  font-size: 16px;    
  font-weight: 700;
}
.KpiTop {
  border-left: 1px solid #202328;
}

.isActive-heading {
  color: white !important;
  background: #0d2c22;

  th {
    color: white !important;
  }
}
.spaceN2 .heading3 {
  padding-left: 16px;
  padding-top: 20px;
}
.no-data-msg-admin{
  color: white !important;
}

.kpi-pointer-check{
  .item-kpi-metric-name{
    font-size: 16px !important;
  }
  .td-kpi-item{
    font-size: 22px;
    span{
      font-size: 12px;
    }
  }
}


.kpitable{
  max-height:600px}
  /* width */
    .kpitable::-webkit-scrollbar {
    width: 4px;
  }
   
  /* Handle */
  .kpitable::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  .kpi-matric-name{
    vertical-align: middle !important;
  }