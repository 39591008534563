.ScoreChild {
  padding: 30px;
  padding-bottom: 0;
  .component-section-isActive {
    box-shadow: 0px 4px 6px rgb(0 0 0 / 6%);
    background: rgb(103 109 124 / 26%);
    padding: 25px 5px;
    border-radius: 20px;
    margin-bottom: 26px;
  }
  .cardio-system {
    text-align: center;
    margin: 0 10px;
  }
  .scorecard-section {
    display: flex;
    overflow-x: auto;
  }
  .circular-bar {
    margin: auto;
  }
  .imgDiv {
    width: 150px;
  }
  .component-section1 {
    padding: 5px 5px;
    margin-bottom: 10px;
  }
  .factorclr {
    font-size: 18px;
  }
  .postivefactor1 {
    padding: 20px 15px;
    margin-left: 10px;
  }
  .postivefactor2 {
    padding:20px 15px;
    margin-right: 10px;
  }
  h3.heading3 {
    margin-bottom: 20px;
  }
  .isActive{
    box-shadow: 0px 4px 6px rgb(0 0 0 / 6%);
    background: rgb(103 109 124 / 26%);
  }
}
